import type { IconPosition } from '@domains/shared/helpers/styles/getIconStylesForInteractiveElement';
import { getIconStylesForInteractiveElement } from '@domains/shared/helpers/styles/getIconStylesForInteractiveElement';
import type { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { SIZE, WEIGHT } from '@lib/styles/partials/typography';

/*
 * These styles are strictly related to TextButton.theme.tsx!
 * Any changes added here must also be added also to TextButton.theme.tsx file.
 */

interface ButtonProps {
    icon: IconPosition;
    isInline: boolean;
    isDisabled: boolean;
}

export const PrimaryLinkTextButton = styled.a<ButtonProps>`
    padding: ${({ isInline }): string => (isInline ? '0' : '12px 16px')};
    border: none;
    outline: none;
    background: none;
    color: ${({ theme }): string => theme.deprecated.domains.shared.textButton.primary.color};
    font-size: ${SIZE.p3};
    font-weight: ${WEIGHT.semibold};
    line-height: 1;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    user-select: none;

    &:hover,
    &:focus,
    &:active {
        text-decoration: underline;
    }

    ${({ theme, isDisabled }): string =>
        isDisabled
            ? `
            pointer-events: none;

            &,
            &:hover,
            &:focus,
            &:active {
                color: ${theme.deprecated.domains.shared.textButton.disabled.color};
                text-decoration: none;
            }`
            : ''};

    ${({ icon }): SerializedStyles | undefined => getIconStylesForInteractiveElement(icon)}
`;

export const SecondaryLinkTextButton = styled(PrimaryLinkTextButton)`
    color: ${({ theme }): string => theme.deprecated.domains.shared.textButton.secondary.color};
`;
