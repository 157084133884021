import { H2 } from '@domains/shared/components/Heading/Heading.theme';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { SIZE, WEIGHT } from '@lib/styles/partials/typography';

export const Container = styled.div<{ isOneColumn: boolean; hasTitle: boolean }>`
    display: grid;
    padding-bottom: 24px;

    & > div:first-of-type {
        border-top: 1px solid
            ${({ theme }): string => theme.deprecated.domains.ad.adOverview.table.tableItem.borderColor};
    }

    @media (min-width: ${BREAKPOINT.sm}) {
        grid-gap: 0 16px;
        grid-template-columns: ${({ isOneColumn }): string =>
            isOneColumn ? 'minmax(0, 1fr)' : 'repeat(2, minmax(0, 1fr))'};
        padding-top: ${({ hasTitle }): string => (hasTitle ? '0' : '28px')};

        & > div:nth-of-type(2) {
            border-top: ${({ theme, isOneColumn }): string =>
                isOneColumn
                    ? 'none'
                    : `1px solid ${theme.deprecated.domains.ad.adOverview.table.tableItem.borderColor}`};
        }
    }
`;

export const Title = styled(H2)`
    margin-top: 54px;
    padding-bottom: 16px;
    font-size: ${SIZE.h5};
    font-weight: ${WEIGHT.bold};

    @media (min-width: ${BREAKPOINT.sm}) {
        padding-bottom: 24px;
    }
`;
