import type { FC, ReactNode } from 'react';

import {
    Breadcrumb,
    Container,
    Content,
    Header,
    NewProjectPageContent,
    Sidebar,
    StickySidebar,
} from './TwoColumnContentLayout.theme';

interface Props {
    breadcrumb: ReactNode;
    header?: ReactNode;
    content: ReactNode;
    sidebar: ReactNode;
    showBrandingMargin?: boolean;
    isNewProjectPage?: boolean;
}

export const TwoColumnContentLayout: FC<Props> = ({
    breadcrumb,
    header,
    content,
    sidebar,
    showBrandingMargin = false,
    isNewProjectPage = false,
    ...htmlProps
}) => {
    return (
        <Container showBrandingMargin={showBrandingMargin} showHeader={!!header} {...htmlProps}>
            <Breadcrumb>{breadcrumb}</Breadcrumb>
            {header ? <Header>{header}</Header> : null}
            {isNewProjectPage ? <NewProjectPageContent>{content}</NewProjectPageContent> : <Content>{content}</Content>}
            {isNewProjectPage ? (
                <StickySidebar withBrandingMargin={showBrandingMargin}>{sidebar}</StickySidebar>
            ) : (
                <Sidebar>{sidebar}</Sidebar>
            )}
        </Container>
    );
};
