import type { ComponentProps, ForwardRefRenderFunction } from 'react';
import { forwardRef } from 'react';

import { PrimaryLinkTextButton, SecondaryLinkTextButton } from './LinkTextButton.theme';

type LinkTextButtonVariant = 'primary' | 'secondary';

interface Props extends ComponentProps<'a'> {
    icon?: 'left' | 'right' | 'none';
    isInline?: boolean;
    variant?: LinkTextButtonVariant;
    isDisabled?: boolean;
}

const LINK_TEXT_BUTTON = {
    primary: PrimaryLinkTextButton,
    secondary: SecondaryLinkTextButton,
} as const;

/*
 * These components are strictly related to TextButton.tsx!
 * Any changes added here must also be added also to TextButton.tsx file.
 */

const LinkTextButtonElement: ForwardRefRenderFunction<HTMLAnchorElement, Props> = (
    { variant = 'primary', icon = 'none', isDisabled = false, isInline = false, children, ...htmlProps },
    ref,
) => {
    const StyledLinkTextButton = LINK_TEXT_BUTTON[variant];

    return (
        <StyledLinkTextButton
            {...htmlProps}
            tabIndex={isDisabled ? -1 : 0}
            isDisabled={isDisabled}
            icon={icon}
            isInline={isInline}
            ref={ref}
        >
            {children}
        </StyledLinkTextButton>
    );
};

export const LinkTextButton = forwardRef<HTMLAnchorElement, Props>(LinkTextButtonElement);
