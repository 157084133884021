import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import { GoBackButton } from '@domains/shared/components/GoBackButton/GoBackButton';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { SIZE } from '@lib/styles/partials/typography';

export const Container = styled.div`
    display: flex;
    max-width: 100vw;
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    @media (min-width: ${BREAKPOINT.md}) {
        display: flex;
        align-items: center;
    }
`;

export const Breadcrumb = styled.a`
    display: flex;
    align-items: center;
    margin: 0 8px 0 0;
    color: ${({ theme }): string => theme.deprecated.domains.ad.adBreadcrumbs.color};
    font-size: ${SIZE.p3};
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

export const BlankBreadcrumb = styled(Breadcrumb)`
    &:hover {
        text-decoration: none;
    }
`.withComponent('span');

export const ArrowRight = styled(Icon)`
    margin-left: 8px;
`;

export const ArrowWrapper = styled.div<{ size?: ObjectValues<typeof SIZE> }>`
    font-size: ${({ size }): ObjectValues<typeof SIZE> => size || SIZE.p5};
`;

export const StyledGoBackButton = styled(GoBackButton)`
    margin-right: 16px;
`;
