import { H2 } from '@domains/shared/components/Heading/Heading.theme';
import styled from '@emotion/styled';
import { SIZE, WEIGHT } from '@lib/styles/partials/typography';
import { theme } from '@lib/styles/themes/nexus';
import type { CSSProperties } from 'react';

export const Title = styled(H2)<{ isBorderless?: boolean; hasMinimalSpacing?: boolean }>`
    padding-top: 32px;
    padding-bottom: 16px;
    color: ${({ theme }): CSSProperties['color'] => theme.deprecated.domains.ad.adSectionHeading.color};
    font-size: ${SIZE.h5};
    font-weight: ${WEIGHT.bold};

    &::before {
        content: '';
        display: block;
        padding-bottom: ${({ hasMinimalSpacing }): CSSProperties['paddingBottom'] =>
            hasMinimalSpacing ? 0 : theme.space[32]};
        border-top: ${({ isBorderless }): CSSProperties['borderTopWidth'] => (isBorderless ? '0' : '1')} solid
            ${({ theme }): CSSProperties['borderTopColor'] => theme.deprecated.domains.ad.adSectionHeading.borderColor};
    }
`;
