import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import { useTracking } from '@lib/tracking/useTracking';
import type { FC } from 'react';

import { DescriptionLink } from './TableItem.theme';

interface Props {
    descriptionText: string;
    title: string;
    link: string;
}

export const AdInformationLink: FC<Props> = ({ descriptionText, title, link }) => {
    const { trackEvent } = useTracking();

    const onClick = (): void => {
        trackEvent('ad_parameter_click', {
            touch_point_button: title,
            ad_parameter_value: descriptionText,
        });
    };

    return (
        <LocalLink href={link} passHref>
            <DescriptionLink onClick={onClick} data-cy="ad-information-link">
                {descriptionText}
            </DescriptionLink>
        </LocalLink>
    );
};
