import { useIntersection } from '@domains/shared/hooks/useIntersection/useIntersection';
import type { AdvertAgencyType } from '@type/ad/advertAgency';
import type { AdvertCharacteristic } from '@type/ad/advertCharacteristic';
import type { AdvertCategory } from '@type/ad/attributes/advertCategory';
import type { SellerBadge } from '@type/sellerBadge/sellerBadge';
import type { AdvertUserType } from '@type/user/userType';
import dynamic from 'next/dynamic';
import type { JSX } from 'react';
import { useRef, useState } from 'react';

import { Container } from './AdAgencyBanner.theme';
import { BannerContentSkeleton } from './BannerContent.skeleton';

const LazyBannerContent = dynamic(() => import('./BannerContent'), {
    loading: () => <BannerContentSkeleton />,
});

export interface AdAgencyBannerProps {
    adId: number;
    agencyId: string | number;
    name?: string;
    adCategory: AdvertCategory;
    type?: AdvertUserType | AdvertAgencyType;
    url?: string | null;
    phones?: string[];
    imageUrl?: string;
    address?: string;
    licenseNumber?: string;
    sellerBadges?: SellerBadge[];
    characteristics?: AdvertCharacteristic[];
}

export const AdAgencyBanner = (props: AdAgencyBannerProps): JSX.Element => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [isIntersecting, setIsIntersecting] = useState(false);

    useIntersection(
        containerRef,
        () => {
            setIsIntersecting(true);
        },
        { threshold: 0.2 },
    );

    return (
        <Container ref={containerRef}>
            {isIntersecting ? <LazyBannerContent {...props} /> : <BannerContentSkeleton />}
        </Container>
    );
};
