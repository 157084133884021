import { Container as GridContainer } from '@domains/shared/components/Container/Container';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import type { CSSProperties } from 'react';

const getContainerGridAreasLgBreakpoint = ({
    showHeader,
}: {
    showHeader: boolean;
}): CSSProperties['gridTemplateAreas'] => {
    return showHeader
        ? '"breadcrumb breadcrumb" "header header" "content sidebar"'
        : '"breadcrumb breadcrumb" "content sidebar"';
};

const GRID_AREA_WITH_HEADER = '"breadcrumb" "header" "content" "sidebar"';
const GRID_AREA_WITHOUT_HEADER = '"breadcrumb" "content" "sidebar"';

export const Container = styled(GridContainer)<{
    showBrandingMargin: boolean;
    showHeader: boolean;
}>`
    display: grid;
    grid-gap: 16px;
    grid-template-areas: ${({ showHeader }): string => (showHeader ? GRID_AREA_WITH_HEADER : GRID_AREA_WITHOUT_HEADER)};
    grid-template-columns: 100%;

    @media (min-width: ${BREAKPOINT.md}) {
        margin-top: ${({ showBrandingMargin }): string => (showBrandingMargin ? '46px' : '0')};
    }

    @media (min-width: ${BREAKPOINT.lg}) {
        grid-template-areas: ${getContainerGridAreasLgBreakpoint};
        grid-template-columns: 844px 300px;
        margin-top: ${({ showBrandingMargin }): string => (showBrandingMargin ? '48px' : '0')};
    }
`;

export const Breadcrumb = styled.div`
    grid-area: breadcrumb;
    margin: 20px 0 4px;
`;

export const Header = styled.div`
    grid-area: header;
`;

export const Content = styled.div`
    grid-area: content;
`;

export const NewProjectPageContent = styled(Content)`
    margin-top: 32px;
`;

export const Sidebar = styled.aside`
    grid-area: sidebar;
`;

const BRAND_HEADER_HEIGHT = 48;

export const StickySidebar = styled(Sidebar)<{ withBrandingMargin?: boolean }>`
    @media (min-width: ${BREAKPOINT.lg}) {
        position: sticky;
        top: ${({ withBrandingMargin }): string => `${60 + (withBrandingMargin ? BRAND_HEADER_HEIGHT : 0)}px`};
        align-self: start;
        margin-top: 32px;
    }
`;
