import styled from '@emotion/styled';
import { SIZE, WEIGHT } from '@lib/styles/partials/typography';
import { theme } from '@lib/styles/themes/nexus';
import type { CSSProperties } from 'react';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0 ${theme.space['16']} 0 0;
    overflow: hidden;
    gap: ${theme.space['8']};
`;

export const AgencyName = styled.span`
    overflow: hidden;
    color: ${({ theme }): CSSProperties['color'] => theme.deprecated.domains.ad.brandingAgencyName.color};
    font-size: ${SIZE.p4};
    font-weight: ${WEIGHT.semibold};
    line-height: normal;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (max-width: ${theme.breakpoints.sm}) {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
    }
`;
