import { LinkTextButton } from '@domains/shared/components/LinkTextButton/LinkTextButton';
import type { CSSProperties } from '@emotion/serialize';
import styled from '@emotion/styled';
import { SIZE } from '@lib/styles/partials/typography';

export const LocationLinkTextButton = styled(LinkTextButton)<{ isNewProjectPageAdLocation: boolean }>`
    margin-right: auto;
    padding: 0;
    font-size: ${({ isNewProjectPageAdLocation }): CSSProperties['fontSize'] =>
        isNewProjectPageAdLocation ? SIZE.p2 : SIZE.p3};
    text-decoration: ${({ isNewProjectPageAdLocation }): CSSProperties['textDecoration'] =>
        isNewProjectPageAdLocation ? 'underline' : 'none'};
`;
