import { AdPageModalContext } from '@domains/ad/contexts/AdPageModalContext';
import { TextButton } from '@domains/shared/components/TextButton/TextButton';
import { CONTACT_FORM_TYPE } from '@domains/shared/consts/contactFormType';
import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { JSX } from 'react';
import { useContext } from 'react';

interface Props {
    t: Translator;
    touchPointButton: string;
}
const AskForMissingInfoButton = ({ t, touchPointButton }: Props): JSX.Element => {
    const { trackEvent } = useTracking();
    const { toggleModalState, setContactFormTrackingData } = useContext(AdPageModalContext);

    const handleClick = (): void => {
        toggleModalState('contactFromMissingInfo');
        setContactFormTrackingData({ contact_form_type: CONTACT_FORM_TYPE.missingAdParameters });
        trackEvent('reply_message_form_click', {
            touch_point_page: 'ad_page',
            touch_point_button: touchPointButton,
            contact_form_type: CONTACT_FORM_TYPE.missingAdParameters,
        });
    };

    return (
        <TextButton data-cy="missing-info-button" isInline={true} onClick={handleClick}>
            {t('frontend.ad-overview.table.ask-for-missing-information')}
        </TextButton>
    );
};

export default AskForMissingInfoButton;
