import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';
import type { FC } from 'react';

import { LocationLinkTextButton } from './AdLocation.theme';

interface Props {
    ariaLabel?: string;
    address: string;
    href: string;
    className?: string;
    onClick?: () => void;
    isNewProjectPageAdLocation?: boolean;
}

export const AdLocation: FC<Props> = ({
    ariaLabel,
    address,
    href,
    onClick = (): void => undefined,
    isNewProjectPageAdLocation = false,
}) => (
    <LocationLinkTextButton
        aria-label={ariaLabel}
        onClick={onClick}
        href={href}
        icon="left"
        isNewProjectPageAdLocation={isNewProjectPageAdLocation}
    >
        {isNewProjectPageAdLocation ? null : <Icon icon={faMapMarkerAlt} aria-hidden="true" width="11" height="14" />}
        {address}
    </LocationLinkTextButton>
);
