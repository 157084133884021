import styled from '@emotion/styled';
import { SIZE } from '@lib/styles/partials/typography';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 32px 0;
    padding: 24px 16px;
    background-color: ${({ theme }): string => theme.deprecated.domains.ad.adAgencyBannerContent.backgroundColor};
    font-size: ${SIZE.p3};
    text-align: center;

    & > span {
        width: 100%;
    }
`;
