import AskForMissingInfoButton from '@domains/ad/components/Table/AskForMissingInfoButton';
import { DESCRIPTION_LINK_REGEXP } from '@domains/ad/components/Table/tableDescriptionFormaters';
import { replacePlaceholders } from '@domains/shared/helpers/replacePlaceholders';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';

import { AdInformationLink } from './AdInformationLink';
import { Column, DescriptionContent, IconImg, ItemContainer, LabelContent, StyledTooltipIcon } from './TableItem.theme';
import type { ObservableResize, UnobservableResize } from './useElementOnResize';

interface Props {
    description?: string;
    icon?: string;
    observeResize: ObservableResize;
    isOneColumn: boolean;
    title: string;
    unobserveResize: UnobservableResize;
    shouldRenderAskForInformation?: boolean;
    label: string;
}

export const TableItem: FC<Props> = ({
    description,
    icon,
    observeResize,
    isOneColumn,
    title,
    unobserveResize,
    shouldRenderAskForInformation,
    label,
}) => {
    const [t] = useTranslations();
    const [shouldUseTooltipForLabel, setShouldUseTooltipForLabel] = useState(false);
    const [shouldUseTooltipForDescription, setShouldUseTooltipForDescription] = useState(false);
    const labelRef = useRef<HTMLDivElement | null>(null);
    const descriptionRef = useRef<HTMLDivElement | null>(null);
    const hasData = !!description?.length;
    const descriptionText =
        description?.replace(/<C>/g, '').replace(new RegExp(DESCRIPTION_LINK_REGEXP, 'g'), '$1') ||
        t('frontend.ad-overview.table.no-information');

    const descriptionTextWithLinks =
        !!description &&
        replacePlaceholders(description, (text) => {
            const link = text.match(DESCRIPTION_LINK_REGEXP);

            if (link) {
                const [, label, url] = link;

                return <AdInformationLink descriptionText={label} title={title} link={url} />;
            }

            return text;
        });

    const labelElement = (
        <LabelContent data-cy="table-label-content" ref={labelRef}>
            {title}
        </LabelContent>
    );

    const shouldGreyedOut = !shouldRenderAskForInformation && !hasData;
    const descriptionElement =
        shouldRenderAskForInformation && !description ? (
            <AskForMissingInfoButton t={t} touchPointButton={label} />
        ) : (
            <DescriptionContent hasData={hasData} ref={descriptionRef} data-testid={`table-value-${label}`}>
                {descriptionTextWithLinks || descriptionText}
            </DescriptionContent>
        );

    useEffect(() => {
        // when change to mobile, white-space in CSS is set to normal
        const onResize = (): void => {
            const { current: descriptionElement } = descriptionRef;
            const { current: labelElement } = labelRef;

            if (descriptionElement) {
                setShouldUseTooltipForDescription(
                    descriptionElement.scrollWidth > descriptionElement.offsetWidth ||
                        descriptionElement.scrollHeight > descriptionElement.offsetHeight,
                );
            }
            if (labelElement) {
                setShouldUseTooltipForLabel(
                    labelElement.scrollWidth > labelElement.offsetWidth ||
                        labelElement.scrollHeight > labelElement.offsetHeight,
                );
            }
        };

        observeResize(onResize);

        return (): void => {
            unobserveResize(onResize);
        };
    }, [observeResize, isOneColumn, unobserveResize]);

    return (
        <ItemContainer aria-label={title} hasIcon={!!icon} role="region" isOneColumn={isOneColumn}>
            {icon ? <IconImg hasData={hasData} src={icon} alt="" /> : null}
            <Column shouldGreyedOut={shouldGreyedOut}>
                {shouldUseTooltipForLabel ? (
                    <StyledTooltipIcon ariaLabel={false} description={title} icon={labelElement} tabIndex={-1} />
                ) : (
                    labelElement
                )}
            </Column>
            <Column shouldGreyedOut={shouldGreyedOut}>
                {shouldUseTooltipForDescription ? (
                    <StyledTooltipIcon
                        ariaLabel={false}
                        description={descriptionText}
                        icon={descriptionElement}
                        tabIndex={-1}
                    />
                ) : (
                    descriptionElement
                )}
            </Column>
        </ItemContainer>
    );
};
