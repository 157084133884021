import type { ForwardRefRenderFunction, ReactNode } from 'react';
import { forwardRef } from 'react';

import { Title } from './AdSectionHeading.theme';

interface Props {
    children: ReactNode;
    className?: string;
    isBorderless?: boolean;
    hasMinimalSpacing?: boolean;
}

const Heading: ForwardRefRenderFunction<HTMLHeadingElement, Props> = (
    { children, className, isBorderless, hasMinimalSpacing },
    ref,
) => {
    return (
        <Title ref={ref} className={className} isBorderless={isBorderless} hasMinimalSpacing={hasMinimalSpacing}>
            {children}
        </Title>
    );
};

export const AdSectionHeading = forwardRef<HTMLHeadingElement, Props>(Heading);
