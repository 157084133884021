import { LinkTextButton } from '@domains/shared/components/LinkTextButton/LinkTextButton';
import { TextButton } from '@domains/shared/components/TextButton/TextButton';
import { TooltipIcon } from '@domains/shared/components/TooltipIcon/TooltipIcon';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { LINE_HEIGHT, SIZE, WEIGHT } from '@lib/styles/partials/typography';

export const DescriptionLink = styled.a`
    color: inherit;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

export const ItemContainer = styled.div<{
    hasIcon: boolean;
    isRemoteServiceList?: boolean;
    isOneColumn?: boolean;
}>`
    display: grid;
    grid-column-gap: 16px;
    grid-template-columns: ${({ hasIcon }): string => (hasIcon ? '16px ' : '')} 1fr 1fr;
    height: auto;
    padding: 8px 0;
    border-bottom: 1px solid
        ${({ theme }): string => theme.deprecated.domains.ad.adOverview.table.tableItem.borderColor};
    color: ${({ theme }): string => theme.deprecated.domains.ad.adOverview.table.tableItem.color};
    font-size: ${SIZE.h6};
    line-height: ${LINE_HEIGHT.x2};

    @media (min-width: ${BREAKPOINT.sm}) {
        grid-template-columns: ${({ hasIcon }): string => (hasIcon ? '16px ' : '')} ${({ isOneColumn }): string =>
                isOneColumn ? '250px ' : '1fr'} 1fr;
        height: ${({ isRemoteServiceList, isOneColumn }): string =>
            isRemoteServiceList || isOneColumn ? 'auto' : '40px'};
        white-space: ${({ isOneColumn }): string => (isOneColumn ? 'normal' : 'nowrap')};
    }
`;

export const Column = styled.div<{ shouldGreyedOut: boolean }>`
    display: flex;
    position: relative;
    min-width: 0;
    opacity: ${({ shouldGreyedOut }): number => (shouldGreyedOut ? 0.5 : 1)};
`;

/* white-space: label is displayed always in one line, no matter the breakpoint */
export const LabelContent = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const IconImg = styled.img<{ hasData: boolean }>`
    width: 16px;
    height: 16px;
    margin-top: 3px;
    opacity: ${({ hasData }): number => (hasData ? 1 : 0.5)};
`;

export const DescriptionContent = styled.div<{ hasData?: boolean }>`
    overflow: hidden;
    font-weight: ${({ hasData }): number => (hasData ? WEIGHT.bold : WEIGHT.regular)};
    text-overflow: ellipsis;
`;

/* margin-right: Space for the icon */
export const RemoteServices = styled.div<{ isRemoteServicesFlagActive: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-width: 0;
    margin-right: ${({ isRemoteServicesFlagActive }): string => (isRemoteServicesFlagActive ? '16px' : '0')};
`;

export const StyledTextButton = styled(TextButton)`
    display: block;
    max-width: 100%;
    margin-right: auto;
    padding: 4px 0;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const StyledLinkTextButton = StyledTextButton.withComponent(LinkTextButton);

export const TooltipIconInfo = styled(TooltipIcon)`
    position: absolute;
    top: 4px;
    right: 0;
    color: ${({ theme }): string => theme.deprecated.domains.ad.adOverview.table.tooltipInfoIcon.color};
    font-size: ${SIZE.p3};
`;

export const StyledTooltipIcon = styled(TooltipIcon)`
    display: block;
    max-width: 100%;
    margin: 0;
    line-height: inherit;
`;
