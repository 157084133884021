import { Container as ContentContainer } from '@domains/shared/components/Container/Container';
import type { CSSProperties } from '@emotion/serialize';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { SIZE, WEIGHT } from '@lib/styles/partials/typography';

export const BrandingContainer = styled.div`
    height: auto;
    background-color: ${({ theme }): CSSProperties['backgroundColor'] =>
        theme.deprecated.domains.ad.brandingHeader.backgroundColor};

    &:focus,
    &:hover {
        background-color: ${({ theme }): CSSProperties['backgroundColor'] =>
            theme.deprecated.domains.ad.brandingHeader.hover.backgroundColor};
    }

    @media (min-width: ${BREAKPOINT.sm}) {
        height: 48px;

        &:focus,
        &:hover {
            background-color: ${({ theme }): CSSProperties['backgroundColor'] =>
                theme.deprecated.domains.ad.brandingHeader.backgroundColor};
        }
    }
`;

export const BrandingContent = styled(ContentContainer)`
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    height: 100%;
    padding: 8px 16px;

    @media (min-width: ${BREAKPOINT.md}) {
        grid-template-columns: auto auto 1fr;
    }
`;

export const MobileBrandingContainer = styled.a`
    text-decoration: none;
`;

export const AgencyLogo = styled.img`
    width: auto;
    max-width: 96px;
    height: 32px;
    margin-right: 8px;
    object-fit: contain;
`;

export const ShowAllAdsText = styled.button`
    width: fit-content;
    border: 0;
    background: none;
    color: ${({ theme }): CSSProperties['color'] => theme.deprecated.domains.ad.showAllAdsLink.color};
    font-size: ${SIZE.p4};
    font-weight: ${WEIGHT.semibold};
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;
