import { AdSectionHeading } from '@domains/ad/components/AdSectionHeading/AdSectionHeading';
import { useIntersection } from '@domains/shared/hooks/useIntersection/useIntersection';
import { useTracking } from '@lib/tracking/useTracking';
import type { AdvertLocationDetails } from '@type/location/advertLocation';
import dynamic from 'next/dynamic';
import type { JSX } from 'react';
import { memo, useRef, useState } from 'react';

import { AdMapSkeleton } from './AdMap.skeleton';

interface Props {
    title: string;
    noExactLocationLabel?: string;
    location: AdvertLocationDetails;
}

const LazyAdMap = dynamic(() => import('./Map'), {
    loading: () => <AdMapSkeleton />,
    ssr: false,
});

const BaseAdMap = (props: Props): JSX.Element => {
    const { title, noExactLocationLabel, location } = props;

    const titleRef = useRef<HTMLHeadingElement>(null);
    const [isIntersecting, setIsIntersecting] = useState(false);
    const [isImpressionTracked, setIsImpressionTracked] = useState(false);

    const { trackEvent } = useTracking();

    useIntersection(titleRef, () => {
        setIsIntersecting(true);

        if (!isImpressionTracked) {
            trackEvent('map_ad_page_impression', {
                touch_point_page: 'ad_page',
            });
            setIsImpressionTracked(true);
        }
    });

    return (
        <div id="map">
            <AdSectionHeading ref={titleRef}>{title}</AdSectionHeading>
            {isIntersecting ? (
                <LazyAdMap location={location} noExactLocationLabel={noExactLocationLabel} />
            ) : (
                <AdMapSkeleton />
            )}
        </div>
    );
};

export const AdMap = memo(BaseAdMap);
