import { getAgencyUrl } from '@domains/ad/helpers/getAgencyUrl';
import { getFormattedAgencyName } from '@domains/ad/helpers/getFormattedAgencyName';
import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTrackImpression } from '@domains/shared/hooks/useTrackImpression/useTrackImpression';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { Agency } from '@type/ad/advertAgency';
import type { AdvertCategory } from '@type/ad/attributes/advertCategory';
import type { AgencyOwner } from '@type/ad/attributes/advertOwner';
import { checkIsAgencyOwner } from '@type/ad/attributes/advertOwner';
import type { SellerBadge } from '@type/sellerBadge/sellerBadge';
import type { FC } from 'react';
import { useContext, useRef } from 'react';

import {
    AgencyLogo,
    BrandingContainer,
    BrandingContent,
    MobileBrandingContainer,
    ShowAllAdsText,
} from './AdAgencyBranding.theme';
import { AgencyNameWithLeaderBadge } from './AgencyNameWithLeaderBadge';

const checkIsAgency = (value: Agency | AgencyOwner): value is Agency => !checkIsAgencyOwner(value as AgencyOwner);

interface Props {
    agency: AgencyOwner | Agency;
    adCategory: AdvertCategory;
    sellerBadges?: SellerBadge[];
}

const TRACKING_DATA = {
    touch_point_button: 'sticky_bar',
};

export const AdAgencyBranding: FC<Props> = ({ agency, adCategory, sellerBadges = [] }) => {
    const [t] = useTranslations();
    const { lang } = useSiteSettings();
    const { isTablet, isDesktop } = useContext(RWDContext);
    const { trackEvent } = useTracking();

    const isAgency = checkIsAgency(agency);
    const imageUrl = isAgency ? agency.imageUrl : agency.image?.url;
    const name = isAgency ? agency.name : agency.companyName;
    const isSmallMobile = !isTablet && !isDesktop;
    const agencyUrl = getAgencyUrl({ agency, estate: adCategory.name, transaction: adCategory.type, lang });

    const trackShowAllAdsButtonClick = (): void => {
        trackEvent('ads_from_seller', TRACKING_DATA);
    };

    const showAllAdsTextRef = useRef<HTMLButtonElement | null>(null);
    useTrackImpression(showAllAdsTextRef, () => trackEvent('ads_from_seller_link_impression', TRACKING_DATA));

    const agencyName = getFormattedAgencyName(name);

    const content = (
        <BrandingContent>
            {!!imageUrl && <AgencyLogo src={imageUrl} alt={agencyName} />}

            <AgencyNameWithLeaderBadge agencyName={agencyName} sellerBadges={sellerBadges} />

            {agencyUrl && isSmallMobile ? (
                <ShowAllAdsText ref={showAllAdsTextRef} onClick={trackShowAllAdsButtonClick} as={'span'}>
                    {t('frontend.ad-page.ad-agency-branding.show-all-short')}
                </ShowAllAdsText>
            ) : null}

            {agencyUrl && !isSmallMobile ? (
                <LocalLink href={agencyUrl}>
                    <ShowAllAdsText ref={showAllAdsTextRef} onClick={trackShowAllAdsButtonClick}>
                        {t('frontend.ad-page.ad-agency-branding.show-all')}
                    </ShowAllAdsText>
                </LocalLink>
            ) : null}
        </BrandingContent>
    );

    if (isSmallMobile) {
        return (
            <BrandingContainer>
                <MobileBrandingContainer href={agencyUrl}>{content}</MobileBrandingContainer>
            </BrandingContainer>
        );
    }

    return <BrandingContainer>{content}</BrandingContainer>;
};
