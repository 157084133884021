import { LeaderBadge } from '@domains/shared/components/nexus/LeaderBadgeIcon/LeaderBadge';
import type { SellerBadge } from '@type/sellerBadge/sellerBadge';

import { AgencyName, Wrapper } from './AgencyNameWithLeaderBadge.theme';

interface Props {
    agencyName: string;
    sellerBadges?: SellerBadge[];
}

export const AgencyNameWithLeaderBadge = ({ agencyName, sellerBadges }: Props): JSX.Element => {
    return (
        <Wrapper>
            <AgencyName>{agencyName}</AgencyName>
            <LeaderBadge sellerBadges={sellerBadges} />
        </Wrapper>
    );
};
