import type { MutableRefObject } from 'react';
import { useCallback, useEffect, useRef } from 'react';

// This hook should be rewritten when used widely
// More:
// * https://groups.google.com/a/chromium.org/forum/?pli=1#!msg/blink-dev/z6ienONUb5A/F5-VcUZtBAAJ
// * https://github.com/jaredLunde/react-hook/blob/master/packages/resize-observer/src/index.tsx

type OnResize = () => void;
export type ObservableResize = (onResize: OnResize) => void;
export type UnobservableResize = (onResize: OnResize) => void;

export const useElementOnResize = (
    isOneColumn: boolean,
    ref: MutableRefObject<HTMLDivElement | null>,
): {
    observeResize: ObservableResize;
    unobserveResize: UnobservableResize;
} => {
    const observableRef = useRef<OnResize[]>([]);

    const observeResize = useCallback<ObservableResize>((onResize: OnResize) => {
        observableRef.current.push(onResize);
    }, []);

    const unobserveResize = useCallback<UnobservableResize>((onResize: OnResize) => {
        observableRef.current = observableRef.current.filter((item) => item !== onResize);
    }, []);

    useEffect(() => {
        const { current: tableElement } = ref;

        if (!tableElement || typeof window === 'undefined' || !('ResizeObserver' in window)) {
            return;
        }
        let isSubscribed = true;
        let debouncedResizeObserverTimeoutId: ReturnType<typeof setTimeout>;

        const resizeObserver = new ResizeObserver(() => {
            clearTimeout(debouncedResizeObserverTimeoutId);

            /**
             * Fast browser window resize produces Error: ResizeObserver loop limit exceeded.
             * The error isn't visible in browser console, doesn't affect functionality, but displays in sentry.
             */
            debouncedResizeObserverTimeoutId = setTimeout(() => {
                if (!isSubscribed) {
                    return;
                }

                for (const callable of observableRef.current) callable();
            }, 100);
        });

        resizeObserver.observe(tableElement);

        return (): void => {
            isSubscribed = false;
            observableRef.current = [];
            resizeObserver.unobserve(tableElement);
            resizeObserver.disconnect();
            clearTimeout(debouncedResizeObserverTimeoutId);
        };
    }, [isOneColumn, ref]);

    return { observeResize, unobserveResize };
};
