import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { FC } from 'react';

import { AdLocation } from './AdLocation';

interface Props {
    locationAddress: string;
    isNewProjectPageAdLocation?: boolean;
}

export const AdLocationMapLink: FC<Props> = ({ locationAddress, isNewProjectPageAdLocation = false }) => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();

    return (
        <AdLocation
            ariaLabel={t('frontend.ad-header.address')}
            href="#map"
            address={locationAddress}
            onClick={(): void => {
                trackEvent('show_address');
            }}
            isNewProjectPageAdLocation={isNewProjectPageAdLocation}
        />
    );
};
